import React from 'react';
import axios from 'axios';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import './App.css';

const apiHost = "https://hj1m5k2sfi.execute-api.ap-northeast-1.amazonaws.com";

function App() {
  const [page, setPage] = React.useState("phone");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [formattedPhoneNumber, setFormattedPhoneNumber] = React.useState("");
  const [code, setCode] = React.useState("");
  const [user, setUser] = React.useState("");
  const [text, setText] = React.useState("発信先の電話番号");
  const [buttonText, setButtonText] = React.useState("📞 発信");

  React.useEffect(() => {
    const urlData = window.location.href.split('/');
    const userId = urlData[3];
    setUser(userId);
  }, []);

  React.useEffect(() => {
    if (formattedPhoneNumber) {
      startPhoneCall();
    }
  }, [formattedPhoneNumber]);

  React.useEffect(() => {
    if (page == "phone") {
      setText("発信先の電話番号");
      setButtonText("📞 コードを送信");
    } else if (page == "code") {
      setText("Slackに送られたコード");
      setButtonText("📞 通話を開始");
    } else if (page == "done") {
      setText("通話を開始します");
    }
  }, [page]);


  const startPhoneCall = async () => {
    const api = `${apiHost}/number`;
    try {
      await axios.post(api, {user: user, phoneNumber: formattedPhoneNumber});
      setPage("code");
    } catch(e) {
      console.log(e);
      alert("番号に誤りがあるかネットワークに問題が発生しています");
    }
  };

  const handleCall = async () => {
    if (formattedPhoneNumber) {
      handleCode();
    } else {
      if (phoneNumber) {
        try {
          const parsedNumber = parsePhoneNumberFromString(phoneNumber, 'JP');
          if (parsedNumber.isValid()) {
            setFormattedPhoneNumber(parsedNumber.number);
          } else {
            alert("電話番号に誤りがあります");
          }
        } catch(e) {
          alert("電話番号に誤りがあります");
        }
      }
    }
  };

  const handleCode = async () => {
    const api = `${apiHost}/code`;
    try {
      await axios.post(api, {userId: user, code: code});
      setPage("done");
    } catch(e) {
      console.log(e);
      alert("コードに誤りがあるかネットワークに問題が発生しています");
    }
  };

  return (
    <div className="App">
      <header className="App-header">
        <p>
          {text}
        </p>
        {page == "phone" &&
        <input 
          style={{height:'40px',textAlign:'center',fontSize:'18px',padding:'6px',fontWeight:'bold'}}
          type="tel" 
          id="phone_number" 
          value={phoneNumber} 
          onChange={(e) => {setPhoneNumber(e.target.value);}}
        />
        }
        {page == "code" &&
        <input 
          style={{height:'40px',textAlign:'center',fontSize:'18px',padding:'6px',fontWeight:'bold'}}
          type="tel" 
          id="code" 
          value={code} 
          onChange={(e) => {setCode(e.target.value);}}
        />
        }
        {page !== "done" &&
        <button
          style={{marginTop:'20px',padding:'4px',width:'200px',borderRadius:'4px',fontWeight:'bold',fontSize:'16px'}}
          onClick={handleCall}
        >
          {buttonText}
        </button>
        }
        {page !== "phone" &&
        <button
          style={{marginTop:'20px',padding:'4px',width:'200px',borderRadius:'4px',fontWeight:'bold',fontSize:'16px'}}
          onClick={() => {window.location.reload();}}
        >
          もう一度最初からやり直す
        </button>
        }
      </header>
    </div>
  );
}

export default App;
